import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styleSheet } from "../../assets/styles/style";
import React from "react";
import { useLanguageReducer } from "../../utilities/helpers/Helpers";

export default function ModalComponent(props) {
  const {
    open,
    onClose,
    title,
    maxWidth = "xl",
    fullScreen = false,
    children,
    loading,
    actionBtn,
    component,
    onSubmit,
    onInvalid,
  } = props;

  const LanguageReducer = useLanguageReducer();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
        component: component,
        onSubmit: onSubmit,
        onInvalid: onInvalid,
      }}
    >
      <DialogTitle textAlign={"center"}>
        <Typography fontSize={25} fontWeight={600}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            ...styleSheet.modalDismissButton,
            borderBottomLeftRadius: "20px !important",
          }}
          onClick={onClose}
        >
          {LanguageReducer.DISMISS_TEXT}
        </Button>
        {actionBtn}
      </DialogActions>
    </Dialog>
  );
}
