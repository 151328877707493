import React from "react";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 12px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  // height: 42px !important;
  font-family: "Lato", "Inter", "Arial", sans-serif;

  &:focus {
    border: 2px solid #563ad5;
    outline: none;
  }
`;

const TextAreaComponent = ({
  value,
  onChange,
  maxRows = 4,
  ariaLabel = "textarea",
  placeholder = "Enter text here...",
}) => {
  return (
    <Textarea
      value={value}
      onChange={onChange}
      maxRows={maxRows}
      aria-label={ariaLabel}
      placeholder={placeholder}
    />
  );
};

export default TextAreaComponent;
