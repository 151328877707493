import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ImageListComponent = ({
  itemData,
  cols = 3,
  rowHeight = 164,
  handleRemoveImage,
}) => {
  // Convert the object to an array to map over the items
  const imageArray = Object.values(itemData);

  return (
    <ImageList
      sx={{
        width: 300,
        alignItems: "center",
      }}
      cols={cols}
      rowHeight={rowHeight}
    >
      {imageArray.map((item, index) => (
        <ImageListItem key={index} sx={{ position: "relative" }}>
          <img
            src={item.img}
            alt={item.title}
            loading="lazy"
            style={{ width: "100%", height: "auto" }}
          />
          {/* Add the IconButton to remove the image */}
          <IconButton
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
            }}
            onClick={() => handleRemoveImage(index)}
          >
            <CloseIcon />
          </IconButton>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageListComponent;
