import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Avatar,
  InputLabel,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ImageListComponent from "../../../.reUseableComponents/ImageList/Image";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import TextAreaComponent from "../../../.reUseableComponents/TextField/TextAreaComponent";
import {
  CreateReturnOrder,
  GetAllClientReturnReasonForSelection
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import { EnumOptions } from "../../../utilities/enum";
import {
  fetchMethod,
  GridContainer,
  GridItem,
  useGetLocationOrPathOrSearchParams,
} from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";

const TrackingModal = (props) => {
  const { path } = useGetLocationOrPathOrSearchParams();
  const replacedPath = path.replace("/", "");
  const [clientId, orderId] = replacedPath.split("_");
  const { open, onClose, trackingData } = props;
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({});
  const [returnReasonForSelection, setReturnReasonForSelection] = useState([]);
  const [selectedReasonId, setSelectedReasonId] = useState();
  const [selectedItems, setSelectedItems] = useState({});
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const handleRemoveImage = (index) => {
    const newImages = { ...uploadedImages };
    delete newImages[index];
    setUploadedImages(newImages);
  };
  const handleButtonClick = (index, item) => {
    setSelectedItems((prev) => {
      const newItems = { ...prev };
      if (newItems[index]) {
        delete newItems[index];
      } else {
        newItems[index] = item;
      }
      return newItems;
    });
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const updatedImages = {};
    for (let i = 0; i < files.length; i++) {
      updatedImages[i] = {
        img: URL.createObjectURL(files[i]),
        title: files[i].name,
        files: files[i],
      };
    }
    setUploadedImages(updatedImages);
  };
  const getAllClientReturnReasonForSelection = async () => {
    const { response } = await fetchMethod(() =>
      GetAllClientReturnReasonForSelection(clientId)
    );
    if (response?.result) {
      setReturnReasonForSelection(response?.result);
    }
  };

  const handleCreateReturn = async () => {
    if (Object.keys(uploadedImages).length === 0) {
      errorNotification("Please Upload Product Image");
      return;
    }
    if (
      !selectedReasonId ||
      (typeof selectedReasonId === "object" &&
        Object.keys(selectedReasonId).length === 0) ||
      selectedReasonId.clientReturnReasonId === 0
    ) {
      errorNotification("Please Select Reason");
      return;
    }
    if (
      Object.keys(selectedItems).length === 0 &&
      trackingData?.data?.returnOrder?.orderTypeId === 2
    ) {
      errorNotification("Please Add Product Which You Want To Return");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    Object.keys(uploadedImages).forEach((key) => {
      const file = uploadedImages[key].files;
      if (file) {
        formData.append("file", file);
      }
    });
    formData.append("clientId", clientId);
    formData.append("orderId", trackingData?.data?.returnOrder?.orderId);
    formData.append(
      "clientReturnReasonId",
      selectedReasonId?.clientReturnReasonId
    );
    formData.append("returnComment", textValue);
    formData.append(
      "orderTypeId",
      trackingData?.data?.returnOrder?.orderTypeId
    );
    formData.append("returnCharges", 10);
    const returnProducts = Object.keys(selectedItems).map((key) => {
      const item = selectedItems[key];
      return {
        productId: item.ProductId,
        productStockId: item.ProductStockId,
        itemValue: item.Price,
      };
    });
    formData.append("returnProducts", JSON.stringify(returnProducts));
    try {
      const { response, error } = await fetchMethod(() =>
        CreateReturnOrder(formData)
      );
      if (response.isSuccess) {
        onClose();
        successNotification("Return order created successfully");
        setLoading(false);
      }
    } catch (err) {
      errorNotification("Error while creating return order");
      onClose();
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllClientReturnReasonForSelection();
  }, []);
  return (
    <>
      <ModalComponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        title={"Order Help"}
        actionBtn={
          <ModalButtonComponent
            title={"Create Return"}
            bg={purple}
            loading={loading}
            onClick={(e) => handleCreateReturn()}
          />
        }
      >
        <GridContainer spacing={1}>
          <GridItem
            sm={12}
            md={12}
            lg={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            {Object.keys(uploadedImages).length > 0 && (
              <ImageListComponent
                itemData={uploadedImages}
                cols={1}
                rowHeight={200}
                handleRemoveImage={handleRemoveImage}
              />
            )}
          </GridItem>
          <GridItem
            sm={12}
            md={12}
            lg={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              sx={{
                textTransform: "capitalize  !important",
                height: "29px",
                fontSize: "12px",
              }}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<FileUploadIcon />}
            >
              Upload Image
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileUpload}
                //   multiple
              />
            </Button>
          </GridItem>
          <GridItem sm={12} md={12} lg={12} xs={12}>
            <InputLabel
              required
              sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
            >
              {"Return Reason"}
            </InputLabel>
            <SelectComponent
              name="reason"
              options={returnReasonForSelection}
              value={selectedReasonId}
              optionLabel={EnumOptions.RETURN_REASON.LABEL}
              optionValue={EnumOptions.RETURN_REASON.VALUE}
              onChange={(e, val) => {
                setSelectedReasonId(val);
              }}
            />
          </GridItem>
          <GridItem sm={12} md={12} lg={12} xs={12}>
            <InputLabel sx={{ ...styleSheet.inputLabel, overflow: "unset" }}>
              {"Detail Reason"}
            </InputLabel>
            <TextAreaComponent
              value={textValue}
              maxRows={2}
              onChange={handleTextChange}
              placeholder="Enter Detail Reason..."
            />
          </GridItem>
          {trackingData?.data?.returnOrder?.orderTypeId === 2 ? (
            <GridItem sm={12} xs={12}>
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Image</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Store Name</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trackingData?.data?.returnOrder?.orderItems?.map(
                      (item, index) => (
                        <TableRow key={item.productId}>
                          {" "}
                          <TableCell>
                            <Avatar
                              alt={item.ProductName}
                              src={item.FeatureImage}
                              sx={{
                                width: 40,
                                height: 40,
                                borderRadius: "0px !important",
                              }}
                            />
                          </TableCell>
                          <TableCell>{item.ProductName}</TableCell>
                          <TableCell>
                            {trackingData?.data?.orderAddress?.store?.StoreName}
                          </TableCell>
                          <TableCell>{item.Price}</TableCell>
                          <TableCell>
                            <Button
                              sx={{
                                textTransform: "capitalize  !important",
                                height: "29px",
                                fontSize: "12px",
                              }}
                              variant="contained"
                              color={selectedItems[index] ? "error" : "primary"}
                              onClick={() => handleButtonClick(index, item)}
                            >
                              {selectedItems[index] ? "Added" : "Return"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          ) : null}
        </GridContainer>
      </ModalComponent>
    </>
  );
};

export default TrackingModal;
